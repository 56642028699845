import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/introduction-to-d3",
  "date": "2016-02-25",
  "title": "INTRODUCTION TO D3",
  "author": "admin",
  "tags": ["development", "javascript", "d3"],
  "featuredImage": "feature.jpg",
  "excerpt": "D3 is a data visualization library written in JavaScript. It stands for Data-Driven Documents where Document stand for the DOM or the Docucment Object Model of a wweb page."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What D3 is not?`}</h2>
    <p>{`D3 does not have prebuilt visualizations - data visualization components like charts.
Only works with modern browsers - not compatible with the older versions.
Compatible with web standards so no plugins required like Flash, Silverlight or Java.`}</p>
    <h2>{`Why D3?`}</h2>
    <p>{`Building visualizations with vanilla JavaScript or SVG can quickly become a tedious, difficult task. Let's look at an SVG example.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="SVG Graph" src="https://codepen.io/szaranger/embed/zrbRGX?height=265&theme-id=default&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/zrbRGX'>SVG Graph</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`D3 has declarative syntax, which encapsulates the implementation and provides us with an intuitive interface.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`d3.select('body')
  .selectAll('div')
  .attr('class', 'bar')
  .style('height', function (d) {
    return d * 20 + 'px';
  });
`}</code></pre>
    <p>{`This might not make sense just yet, you can see we are using a function which knows about our data, to set the height.`}</p>
    <h2>{`Using D3`}</h2>
    <p>{`D3 does the hard work for us by automating the repetitive hard bits. D3 comes packed with useful helpers we can use. Let's look at what D3 gives us.`}</p>
    <ul>
      <li parentName="ul">{`Helpers`}</li>
      <li parentName="ul">{`Scales`}</li>
      <li parentName="ul">{`Axes`}</li>
      <li parentName="ul">{`Data`}</li>
    </ul>
    <p>{`To understand above features, let's start by creating a basic graph.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3 Basic Chart" src="https://codepen.io/szaranger/embed/qbvOYo?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/qbvOYo'>D3 Basic Chart</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`Helpers`}</h2>
    <p>{`D3 has helper functions like `}<inlineCode parentName="p">{`min`}</inlineCode>{` and `}<inlineCode parentName="p">{`max`}</inlineCode>{` values of a data set.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var dataset = [ 1, 2, 3, 4, 5, 8 ];

d3.min(dataset); // 1
`}</code></pre>
    <p>{`The source data to D3 is built of Plain Old JavaScript objects, or POJOS.`}</p>
    <p>{`D3 functions commonly take callbacks which in turn takes two arguments `}<inlineCode parentName="p">{`element`}</inlineCode>{` and `}<inlineCode parentName="p">{`index`}</inlineCode>{`. These parameters are usually called passed in as `}<inlineCode parentName="p">{`d`}</inlineCode>{` and `}<inlineCode parentName="p">{`i`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var dataset = [ 1, 2, 3, 4, 5, 8 ];

d3.max(dataset, function(d, i) { return d.amount; }); // 8
`}</code></pre>
    <h2>{`Scales`}</h2>
    <p>{`Scales are objects in D3 that help map values across coordinate systems.`}</p>
    <p>{`Scales have the following attributes:`}</p>
    <ul>
      <li parentName="ul">{`Configured with a `}<inlineCode parentName="li">{`domain`}</inlineCode>{` and a `}<inlineCode parentName="li">{`range`}</inlineCode></li>
      <li parentName="ul">{`Map from the data to teh screen space`}</li>
      <li parentName="ul">{`There are different types of scales - linear and logarithmic`}</li>
    </ul>
    <p><inlineCode parentName="p">{`Domain`}</inlineCode>{` - The data space which its units are our source units.`}</p>
    <p><inlineCode parentName="p">{`Range`}</inlineCode>{` - The screen pixels`}</p>
    <p>{`Let's look at how we can implement a y-scale example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var y = d3.scale.linear()
  .domain([0, 100])
  .range([100, 0]);

y(0); // 100
y(50); // 50
y(100); //0
`}</code></pre>
    <p>{`Now let's apply scaling to our graph.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3 Scaling" src="https://codepen.io/szaranger/embed/MKRBde?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/MKRBde'>D3 Scaling</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`We are overriding the default domain and range by specifying the domain and range using chained syntax.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var yScale = d3.scale.linear()
    .domain([0, 50])
    .range([0, height]);
`}</code></pre>
    <p>{`We were able to bypass the step of figuring out how to map our initial interval (domain) onto our new interval (range).`}</p>
    <h2>{`Axes`}</h2>
    <p>{`D3 has helpers we can use to draw an axis for our graph. D3.js Axis component allows for easy addition of a `}<inlineCode parentName="p">{`horizontal-axis`}</inlineCode>{` and `}<inlineCode parentName="p">{`vertical-axis`}</inlineCode>{` to any graph.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3 Scaling Basic" src="https://codepen.io/szaranger/embed/JGVRoL?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/JGVRoL'>D3 Scaling Basic</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`To add the axis to the SVG graph, we will have to call the axis function on a selection.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var xAxis = d3.svg.axis()
  .scale(axisScale)
  .orient('below')
  .ticks(4);
`}</code></pre>
    <p>{`We use the `}<b>{`call()`}</b>{` operator to call the axis function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var xAxis = d3.svg.axis()
  .scale(axisScale)
  .orient('below')
  .ticks(4);
`}</code></pre>
    <h2>{`Data`}</h2>
    <p>{`Data binding is done differently with D3 `}<inlineCode parentName="p">{`selections`}</inlineCode>{`. They’re powerful because the same selection can be updated for different data later on. Updating is the most powerful part of selections.`}</p>
    <p>{`D3.js figures out for you how to draw the vertical axis line, the vertical axis ticks, the correct spacing of axis ticks to make the axis visually appealing and many other things.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`d3.select(String selector) -> (d3.selection)
`}</code></pre>
    <div className="info">
  <h5>Selections</h5>
  D3 selections are a group of elements that match a query.
    </div>
    <h2>{`D3.max`}</h2>
    <p>{`Let's look at how to use D3's `}<inlineCode parentName="p">{`max`}</inlineCode>{` method to normalize your dataset visually within the specific bounds of a variable domain.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var initialScaleData = [0, 10, 30, 20, 50, 40, 70, 60, 90, 80, 100];

d3.max(initialScaleData); // 100
`}</code></pre>
    <p>{`As you can see, D3.max calculates the maximum value of an array. By using this method we can create a complicated chart as the following.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3: Max" src="https://codepen.io/szaranger/embed/RrzxbQ?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/RrzxbQ'>D3: Max</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`Same as above we can use `}<inlineCode parentName="p">{`d3.min`}</inlineCode>{` to calculate the minimum value of an array.`}</p>
    <h2>{`rangeBands`}</h2>
    <p>{`We can use `}<inlineCode parentName="p">{`rangeBands`}</inlineCode>{` to horizontally scale our D3 charts. When building a chart such as a bar chart in d3, you’ll want an evenly divided space on your x axis. You could divide the space up with your own manual calculations, accounting for space available, bar width, and inner and outer padding. Or, you could have d3 do the calculation for you via a rangeBand.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3: rangeBands" src="https://codepen.io/szaranger/embed/MKMNVy?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/MKMNVy'>D3: rangeBands</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`So for  building a chart such as a bar chart in d3, you’ll want an evenly divided space on your x axis. You could divide the space up with your own manual calculations, accounting for space available, bar width, and inner and outer padding. Or, you could have d3 do the calculation for you via a rangeBand.`}</p>
    <h2>{`Color Scales`}</h2>
    <p>{`Let's explore the use of color scales in our D3 charts.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3: Color Scales" src="https://codepen.io/szaranger/embed/obKqZg?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/obKqZg'>D3: Color Scales</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`We can handle the background color of a filled object with `}<inlineCode parentName="p">{`fill`}</inlineCode>{`, its transparency with `}<inlineCode parentName="p">{`opacity`}</inlineCode>{`, the color of the outline with `}<inlineCode parentName="p">{`stroke`}</inlineCode>{` and the transparency of that color with `}<inlineCode parentName="p">{`stroke-opacity`}</inlineCode>{`.`}</p>
    <p>{`D3 has two color objects: `}<inlineCode parentName="p">{`d3_Rgb`}</inlineCode>{` and `}<inlineCode parentName="p">{`d3_Hsl`}</inlineCode>{`, which describe colors in the two of the most popular color spaces: red/green/blue, and hue/saturation/light.`}</p>
    <h2>{`Quantitative Scales`}</h2>
    <p>{`In D3 quantitative scales allow you do easily divide aspects of your chart evenly based on the dataset. Let's find out the variety of scaling options available in D3.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3: Quantitative Scales" src="https://codepen.io/szaranger/embed/qbeoXB?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/qbeoXB'>D3: Quantitative Scales</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`Margins`}</h2>
    <p>{`Let's look at how to add margins to your D3 charts to give them the room they deserve.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3: Margins" src="https://codepen.io/szaranger/embed/QNLjOQ?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/QNLjOQ'>D3: Margins</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`Scatter Plot`}</h2>
    <p>{`D3 offers a variety of charts you can work with. Let's convert the bar chart into a basic scatter (or bubble) chart.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="D3: Scatter Plot" src="https://codepen.io/szaranger/embed/dMbGvW?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/dMbGvW'>D3: Scatter Plot</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`Conclusion`}</h2>
    <p>{`D3 gives the front-end developer enormous potential in delivering complicated yet sophisticated data visualizations.`}</p>
    <p>{`The Github source for this article can be found in `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/d3-intro"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      